import request from '@/utils/request'
const baseURL = '/baseapi'
const articleApi = {
  ArticleList: '/admin/article/list',
  ArticleInfo: '/admin/article/articleInfo',
  ArticleInfoById: '/admin/article/articleInfoById',
  SaveArticle: '/admin/article/saveArticle',
  DelArticle: '/admin/article/delArticle',
  VerifyArticle: '/admin/article/verifyArticle'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function articleList (parameter) {
  return request({
    baseURL: baseURL,
    url: articleApi.ArticleList,
    method: 'post',
    data: parameter
  })
}

export function articleInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: articleApi.ArticleInfo,
    method: 'post',
    data: parameter
  })
}

export function articleInfoById (parameter) {
  return request({
    baseURL: baseURL,
    url: articleApi.ArticleInfoById,
    method: 'post',
    data: parameter
  })
}

export function saveArticle (parameter) {
  return request({
    baseURL: baseURL,
    url: articleApi.SaveArticle,
    method: 'post',
    data: parameter
  })
}

export function delArticle (parameter) {
  return request({
    baseURL: baseURL,
    url: articleApi.DelArticle,
    method: 'post',
    data: parameter
  })
}

export function verifyArticle (parameter) {
  return request({
    baseURL: baseURL,
    url: articleApi.VerifyArticle,
    method: 'post',
    data: parameter
  })
}
